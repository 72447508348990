import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Flicking from "@egjs/react-flicking";
import { Parallax, Fade as Fading, AutoPlay } from "@egjs/flicking-plugins";
import Collapse from '@material-ui/core/Collapse';
import { SRLWrapper } from "simple-react-lightbox";
import $ from 'jquery';
import ReactGA from "react-ga";


var listItems = <div className="panel"></div>;

var images = null;

const options = {
    settings: {
        disablePanzoom: true,
        overlayColor: "rgb(255, 255, 255, 0.9)",
        autoplaySpeed: 1500,
        transitionSpeed: 500,
        slideAnimationType: 'fade',
    },
    buttons: {
        iconColor: "rgba(255,255,255)",
        backgroundColor: 'rgba(255,224,123)',
        size: '60px',
        showAutoplayButton: false,
        showDownloadButton: false,
        showFullscreenButton: false,
        showThumbnailsButton: false,
    },
    caption: {
        showCaption: false,
    },
    thumbnails: {
        showThumbnails: false,
    },
    progressBar: {
        backgroundColor: '#f2f2f2',
        fillColor: '#000000',
        height: '3px',
        showProgressBar: true
    }
};


class Portfolio extends Component {


    constructor(props) {
        super(props);
        this.flicking = React.createRef();
        this.state = {
            show: false,
            project: {},
            selected: 0,

        };

        this.onClickLeft = this.onClickLeft.bind(this);
        this.onClickRight = this.onClickRight.bind(this);
    }

    componentDidMount() {
        this.scrollListener()
    }


    onClickLeft = () => {
        this.flicking.current.prev(500);
    };

    onClickRight = () => {
        this.flicking.current.next(500);
    };


    hideProject(){
        this.setState({show: false});
        var ele = document.getElementById("portfolio");
        window.scrollTo({left: ele.offsetLeft, top: ele.offsetTop, behavior: "smooth" });
    }


    showProject(project) {
        this.setState({show: true,
        project: project});
        let path = './portfolio';

        images = project.images;
        var ele = document.getElementById("resume");
        window.scrollTo({left: ele.offsetLeft, top: ele.offsetTop, behavior: "smooth" })
        ReactGA.event({
            category: "Portfolio",
            action: project.title,
        });
    }



    scrollListener(){
        let canScroll = true;
        let flicke = this.flicking.current;

        $('#slider').bind( 'DOMMouseScroll mousewheel', function ( event ) {
            if(canScroll) {
                canScroll = false;
                setTimeout(() => {
                    canScroll = true;
                }, 1000);
                if (event.originalEvent.wheelDeltaX > 0) { //alternative options for wheelData: wheelDeltaX & wheelDeltaY
                    //scroll left
                    flicke.prev(500)
                    return false


                } else if (event.originalEvent.wheelDeltaX < 0){
                    //scroll right
                    flicke.next(500)
                    return false

                }
                //prevent page fom scrolling


            }

        });

    }



    render() {

        if(this.props.data){
          listItems = this.props.data.projects.map(function(project){
              return  <div className="panel" key={project.title} id={project.title}>
                  <a onClick={() => this.showProject(project)}><h2>{project.title}<img src={"images/portfolio/" + project.image}/></h2></a>

              </div>
          }.bind(this))

      }


    return (

        <section id="portfolio">
            <div className="drag">
                <h2>(DRAG)</h2>
            </div>

            <div id={"slider"} className="flick">
                <div id="prev"><a title="Previous" onClick={this.onClickLeft}><i className="fa fa-angle-left" aria-hidden="true"></i></a></div>
                <Flicking
                    className="flicking flicking0"
                    gap={30}
                    circular={true}
                    plugins={[new Fading()]}
                    zIndex={1}
                    ref={this.flicking}
                    moveType={"snap"}
                    onMoveEnd={e => {
                        this.setState({ selected: e.index });
                    }}
                    autoResize={true}
                >
                    {listItems}
                </Flicking>
                <div id="next"><a title="Next" onClick={this.onClickRight}><i className="fa fa-angle-right" aria-hidden="true"></i></a></div>

            </div>

            <Collapse in={this.state.show} timeout={1000}>
                <div id="resume">
                    <div id="close-top"><a title="Close" onClick={() => this.hideProject()}><i className="fa fa-times" aria-hidden="true"></i></a></div>
                    <div className="row education">
                        <div className="three columns header-col">
                            <span><h1>{this.state.project.title}</h1></span>
                            <h3>{this.state.project.course}</h3>
                            {this.state.project.links && <ul>See more at: {this.state.project.links.map(function (link) {
                                return <li key={link.name}><a href={link.link} target={"_blank"}>{link.name}</a></li>
                            })}</ul>}
                            {this.state.project.document && <ul>Read more: {this.state.project.document.map(function (doc) {
                                return <li key={doc.file}><a href={"data/" + doc.file} download>{doc.name}</a></li>
                            })}</ul>}
                        </div>

                        <div className="nine columns main-col">
                            <div className="row item">
                                <div className="twelve columns">
                                    <p>{this.state.project.desc}</p>
                                    {this.state.project.video && <div className="videoWrapper"><video playsInline controls width={"560"} height={"349"} crossOrigin={"anonymous"} key={this.state.project.video}><source src={"https://media.frederikkold.dk/" + this.state.project.video} type={"video/mp4"}/>Your browser does not support the video tag. I suggest you upgrade your browser.</video></div>}
                                </div>
                            </div>
                        </div>

                    </div>
                    <SRLWrapper options={options}>
                    <div className="container">
                        <div className="image-grid" style={{ margin: "30px" }}>

                            {images && images.map(function (image) {
                                return <div className="image-item" key={image.name}>
                                    <img className="image" src={"images/portfolio/" + image.name} />
                                </div>
                            })}
                        </div>
                    </div>
                    </SRLWrapper>
                    <div id="close"><a title="Close" onClick={() => this.hideProject()}><i className="fa fa-times" aria-hidden="true"></i></a></div>
                </div>

            </Collapse>


        </section>


    );

  }

}

export default Portfolio;
