import React, { Component } from 'react';

class Header extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var description= this.props.data.description;
    }

    return (
      <header id="home">
      <nav id="nav-wrap">
         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
             <li><a className="smoothscroll" href="#portfolio">Portfolio</a></li>
            <li><a className="smoothscroll" href="#about">About</a></li>
	         {/*<li><a className="smoothscroll" href="#resume">Resume</a></li>*/}

            <li><a className="smoothscroll" href="#contact">Contact</a></li>
         </ul>
      </nav>

      <div className="row banner">
      
         <div className="banner-text">
            <h1 className="responsive-headline">{name}</h1>
            <h3>{description}</h3>

            {/*<ul className="social">
                <a href={"#contact"} className="button btn project-btn smoothscroll">Contact</a>
            </ul>*/}
         </div>
          <div className="header-img">
              <img src="images/profilepic.jpg"/>
          </div>
      </div>

      <p className="scrolldown">
         <a className="smoothscroll" href="#portfolio"><i className="fa fa-angle-down"></i></a>
      </p>

   </header>
    );
  }
}

export default Header;
